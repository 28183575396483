<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="py-2">
        <v-row>

          <!--start: date filter -->
          <v-col class="" cols="12" sm="12">


            <v-row align="center" class="pa-0 ma-0" no-gutters>
              <v-col cols="12" sm="8">
                <div class="d-flex" style="height: 0 !important; visibility: hidden !important">
                  <v-calendar
                      ref="calendar"
                      v-model="requested_date"
                      color="primary"
                      type="day"
                  ></v-calendar>
                  <v-spacer></v-spacer>
                </div>
                <div class="pa-0 px-0 ma-0">

                  <v-btn
                      class="mr-0"
                      color="grey darken-2"
                      outlined
                      @click="setToday"
                  >
                    {{ $t('generic.lang_today') }}
                  </v-btn>
                  <v-btn
                      color="grey darken-2"
                      fab
                      icon
                      text
                      @click="$refs.calendar.prev()"
                  >
                    <v-icon large>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>

                  <v-btn class="elevation-0 text-black border" color="primary" fab small @click="picker=true">
                    {{ new Date(requested_date).getDate() }}
                  </v-btn>

                  <v-btn
                      color="grey darken-2"
                      fab
                      icon
                      small
                      @click="$refs.calendar.next()"
                  >
                    <v-icon large>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                  <strong v-if="$refs.calendar" class="font-weight-bolder">
                    {{ $refs.calendar.title }}
                  </strong>

                </div>
              </v-col>


              <v-col cols="12 text-right" sm="4">
                <v-btn @click="exportPDF" outlined :loading="this.loadingPdf" :disabled="this.loadingPdf" depressed
                       color="warning">
                  <v-icon>picture_as_pdf</v-icon>
                  {{ $t('erp.lang_print') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <!--end: date filter -->

          <v-col cols="12" class="pa-0">
            <Datatable :show-select-checkbox="false" v-if="this.requested_date" :show-header="false" :elevation="0"
                       @editEntry="prepareDepForEdit"
                       :data="this.params"
                       no-excel-export
                       show-edit-buttons ref="breakfastlist"
                       :api-endpoint="ENDPOINTS.DATATABLES.PMS.BREAKFASTLIST"
                       :datatable-headers="datatableHeaders"
            >
              <template v-slot:item.total_rooms="{item}">
                <strong class="primary--text">{{ item.reserved_rooms }}</strong> /<span>{{ item.total_rooms }}</span>
              </template>


              <template v-slot:item.capacity="{item}">
                <strong class="primary--text">{{ item.reserved_places }}</strong> /<span>{{ item.capacity }}</span>
              </template>

              <template v-slot:item.last_update="{value}">
                <span v-if="$moment(value).isValid()">{{ $moment(value).format('DD.MM.YYYY HH:MM') }}</span>
              </template>

              <template v-slot:item.items="{value}">
                <ul>
                  <li v-for="elt in value" :key="elt.id">
                    <strong>{{ elt.quantity }}</strong> x <span>{{ elt.name }}</span>
                  </li>
                </ul>
              </template>
            </Datatable>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>


    <!-- start: edit reservation dialog -->
    <v-dialog scrollable v-if="this.selectedDepartment" max-width="800" v-model="dialog">
      <v-card>
        <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
          {{ this.selectedDepartment.dep_name }} | {{ this.$moment(this.requested_date).format('DD.MM.YYYY') }}
        </v-card-title>

        <v-card-text class="pt-5">
          <v-form lazy-validation ref="form">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL" type="number" :min="0" dense outlined
                              v-model="selectedDepartment.reserved_rooms"
                              :label="this.$t('pms.lang_reserved_rooms')"
                              :rules="[v => !!v && Number(v) <= Number(selectedDepartment.total_rooms)]"
                              :suffix="`/${selectedDepartment.total_rooms}`"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field :suffix="`/${selectedDepartment.capacity}`" @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL" type="number" :min="0" dense outlined
                              v-model="selectedDepartment.reserved_places"
                              :rules="[v => !!v && Number(v) <= Number(selectedDepartment.reserved_places)]"
                              :label="this.$t('pms.lang_reserved_places')"/>
              </v-col>

              <v-col cols="12" class="pt-0">
                <v-text-field
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL" type="number" :min="0" dense outlined
                    v-model="selectedDepartment.total_breakfast"
                    :label="this.$t('pms.lang_total_breakfast')"/>
              </v-col>

              <!--start: items -->
              <v-col cols="12" class="pt-0">
                <v-expansion-panels class="elevation-0">
                  <v-expansion-panel class="elevation-0">
                    <v-expansion-panel-header color="success" class="white--text">{{
                        this.$t("erp.lang_items")
                      }}
                    </v-expansion-panel-header>


                    <v-expansion-panel-content v-if="Array.isArray(this.Items)" class="elevation-0 pt-4">

                      <div class="w-100 text-right">
                        <v-spacer/>
                        <v-btn @click="pushItem" class="mx-auto" color="primary" outlined>
                          {{ $t('generic.lang_add') }}
                        </v-btn>
                      </div>

                      <v-form lazy-validation ref="items_form">
                        <v-data-table ref="items_datatable"
                                      v-if="this.isIssetArr(this.selectedItems)"
                                      :items="this.selectedItems" :headers="this.items_datatable_headers">
                          <template v-slot:item.id="{item}">
                            <v-autocomplete @change="$forceUpdate()"
                                            :rules="[v => !!v ||  $t('generic.lang_select_an_item')]" v-model="item.id"
                                            dense
                                            outlined
                                            hide-details :items="Items"
                                            item-value="id"
                                            item-text="name"/>
                          </template>

                          <template v-slot:item.quantity="{item}">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" :min="1"
                                          type="number" v-model="item.quantity" dense outlined hide-details
                                          item-value="id"
                                          item-text="name"/>
                          </template>

                          <template v-slot:item.price="{item}">
                            {{ itemPrice(item.id) | currency }}
                          </template>


                          <template v-slot:item.total="{item}">
                            {{ (itemPrice(item.id) * item.quantity) | currency }}
                          </template>


                          <template v-slot:item.actions="{item}">
                            <v-btn @click="removeItem(item)" icon color="error">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                </v-expansion-panels>
              </v-col>
              <!--end: items -->


              <!---history-->
              <v-col v-if="this.isIssetArr(this.history)" cols="12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      {{ this.$t('pms.lang_history') }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-timeline>
                        <v-timeline-item
                            v-for="(h, i) in history"
                            :key="i"
                            small
                        >
                          <template v-slot:opposite>
        <span
            :class="`font-weight-bold `"
            v-text="h.user.first_name + ' ' + h.user.last_name"
        ></span>
                          </template>
                          <div class="py-2 pl-5">
                            <h2 class="headline font-weight-light mb-4">
                              {{ $moment(h.updated_date).format('DD.MM.YYYY HH:MM') }}
                            </h2>
                            <div class="">
                              <ul>
                                <li><strong>{{ $t('pms.lang_reserved_rooms') }}:</strong> {{ h.reserved_rooms }}</li>
                                <li><strong>{{ $t('pms.lang_reserved_places') }}:</strong> {{ h.reserved_places }}</li>
                                <li><strong>{{ $t('pms.lang_total_breakfast') }}:</strong> {{ h.total_breakfasts }}</li>
                              </ul>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="card-footer">
          <v-btn @click="closeDialog" color="error" text>
            {{ this.$t('generic.lang_cancel') }}
          </v-btn>

          <v-spacer/>
          <v-btn :loading="this.loading" :disabled="this.loading" @click="save" color="primary" text>
            {{ this.$t('generic.lang_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: edit reservation dialog -->


    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>

<script>
import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from "@/config";
import mixin from '@/mixins/KeyboardMixIns'
import {Events} from "@/plugins/events";
import FileSaver from 'file-saver';

export default {
  name: "PmsBreakfastListComponent",
  components: {
    Datatable,
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      requested_date: null,
      dialog: false,
      selectedDepartment: null,
      loading: false,
      history: null,
      loadingPdf: false,
      Items: [],
      selectedItems: null
    }
  },
  computed: {
    itemPrice() {
      const self = this;

      return (id) => {
        //let's find the item
        if (Array.isArray(self.Items)) {
          let res_item = self.Items.find((elt) => elt.id === id);
          if (res_item)
            return res_item.sellPrice

        }
        return 0;
      }
    },
    items_datatable_headers() {
      return [
        {
          text: this.$t('erp.lang_item'),
          value: 'id'
        },
        {
          text: this.$t('erp.lang_quantity'),
          value: 'quantity'
        },
        {
          text: this.$t('generic.lang_cashier_price'),
          value: 'price'
        },
        {
          text: this.$t('erp.lang_ware_total'),
          value: 'total'
        },
        {
          text: "",
          value: 'actions',
          align: 'right'
        }
      ];
    },
    params() {
      return {
        day: this.$moment(this.requested_date).format('DD.MM.YYYY')
      }
    },
    datatableHeaders() {
      return [
        {
          text: "Id",
          value: 'id',
          hide: true
        },
        {
          text: this.$t('customers.lang_departmentName'),
          value: 'dep_name'
        },
        {
          text: this.$t('erp.lang_rooms'),
          value: 'total_rooms'
        },
        {
          text: '',
          value: 'reserved_rooms',
          hide: true
        },
        {
          text: this.$t('generic.lang_capacity'),
          value: "capacity",
        },
        {
          text: '',
          value: "reserved_places",
          hide: true
        },
        {
          text: this.$t('pms.lang_total_breakfast'),
          value: "total_breakfast",
        },
        {
          text: this.$t('generic.lang_lastchange'),
          value: "last_update", //timestamp
        },
        {
          text: this.$t('erp.lang_items'),
          value: "items",
        },
      ]
    }
  },
  watch: {
    requested_date() {
      if (this.$refs.breakfastlist) this.$refs.breakfastlist.getDataFromApi(this.params);
    },
    dialog(val) {
      if (!val) this.closeDialog();
    },
    selectedDepartment: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    pushItem() {

      if (!this.$refs.items_form.validate()) return;
      if (!this.isIssetArr(this.selectedItems)) this.selectedItems = [];
      this.selectedItems.unshift({
        id: '',
        quantity: 1,
      })

    },
    removeItem(item) {
      if (!Array.isArray(this.selectedItems)) return;

      let index = this.selectedItems.indexOf(item);

      if (index > -1)
        this.selectedItems.splice(index, 1);
    },
    /**
     * download orders list pdf
     */
    exportPDF() {
      this.loadingPdf = true;
      this.axios
          .post(ENDPOINTS.PMS.BREAKFASTLIST.EXPORT, {
                date: this.$moment(this.requested_date).format('DD.MM.YYYY')
              },
              {
                responseType: 'arraybuffer',
              })
          .then((res) => {
            FileSaver.saveAs(new Blob([res.data], {
              type: "application/pdf"
            }), "Breakfast - " + this.$moment(this.requested_date).format('DD.MM.YYYY') + ".pdf");
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message,
            });
          })
          .finally(() => {
            this.loadingPdf = false;
          });
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      this.axios
          .post(ENDPOINTS.PMS.BREAKFASTLIST.UPDATE, {
            id: this.selectedDepartment.id,
            reserved_rooms: this.selectedDepartment.reserved_rooms,
            reserved_places: this.selectedDepartment.reserved_places,
            total_breakfast: this.selectedDepartment.total_breakfast,
            date: this.$moment(this.requested_date).format('DD.MM.YYYY'),
            items: this.selectedItems
          })
          .then((res) => {
            if (res.data.success) {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_actionSuccessful"),
                color: "success",
              });
              this.closeDialog();
              this.$refs.breakfastlist?.getDataFromApi();
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message,
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getHistory(dep_id) {
      return new Promise((resolve, reject) => {
        this.axios
            .post(ENDPOINTS.PMS.BREAKFASTLIST.HISTORY, {
              id: dep_id,
              day: this.$moment(this.requested_date).format('DD.MM.YYYY')
            })
            .then((res) => {
              if (res.data.success) {
                this.history = [...res.data.data]
                resolve(res.data.data)
              }
            })
            .catch((err) => {
              Events.$emit("showSnackbar", {
                color: "error",
                message: this.$t("generic.lang_errorOccurred"),
              });
              reject(err)
            })
            .finally(() => {
              this.loading = false;
            });
      })
    },
    async prepareDepForEdit(department) {
      await this.getHistory(department.id);
      this.selectedDepartment = {...department}
      if (this.isIssetArr(department.items))
        this.selectedItems = [...department.items]
      this.dialog = true;
    },
    closeDialog() {
      this.selectedDepartment = null;
      this.selectedItems = null;
      this.history = null;
      this.dialog = false;
    },
    setToday() {
      this.requested_date = this.$moment().format('YYYY-MM-DD');
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
  },
  async mounted() {
    this.setToday();

    this.Items = await this.$store.dispatch('items/getItems');
  }
}
</script>

<style scoped>

</style>
