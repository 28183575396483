<template>
  <div>
    <page-title :heading="$t('pms.lang_breakfast_list')" :icon=icon :subheading="$t('pms.lang_breakfast_list')"
    ></page-title>
    <div class="app-main__inner">
      <pms-breakfast-list-component/>
    </div>
  </div>
</template>
<script>
import PageTitle from "@/Layout/Components/PageTitle";
import PmsBreakfastListComponent from "@/components/pms/PmsBreakfastListComponent";


export default {
  name: "PmsBreakfastListView",
  components: {
    PmsBreakfastListComponent,
    PageTitle,
  },
  data: () => ({
    icon: 'pe-7s-menu icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>

